import { get, post } from "@/service/index.js";

// 订单-收票-分页查询
export const reqPagingSettlementColInvoice = (param) => {
  return get("/coo/settlement/trade/col-invoice/page", param).then((res) => res);
};

// 订单-收票-关联订单
export const reqDoBindSettlementColInvoice = (data) => {
  return post("/coo/settlement/trade/col-invoice/do-bind", data).then(
    (res) => res
  );
};

// 订单-收票-取消关联
export const reqUnBindSettlementColInvoice = (data) => {
  return post("/coo/settlement/trade/col-invoice/un-bind", data).then(
    (res) => res
  );
};

// 订单-收票-重新关联
export const reqReBindSettlementColInvoice = (data) => {
  return post("/coo/settlement/trade/col-invoice/un-bind", data).then(
    (res) => res
  );
};